body {
    padding: 0;
    margin: 0;
    background-color: #fcfcfb;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}
