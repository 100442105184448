.InfoWindow-container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    gap: 8px;
    line-height: 21px;
}

.InfoWindow-link {
    align-items: center;
    display: flex;
    gap: 8px;
}

.InfoWindow-link > p {
    margin: 6px 0px;
}

.InfoWindow-linkHover {
    padding-left: 6px;
}

.InfoWindow-linkHover:hover > *,
.InfoWindow-linkHover:hover > * > *,
.InfoWindow-linkHover:hover > * > * > * {
    color: #e60000;
    cursor: pointer;
    fill: #e60000;
    transition: all 0.2s;
}

.InfoWindow-icon {
    height: 16px;
    width: 16px;
}
